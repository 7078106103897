



















































































































































































































































































































































































































import { Component, Vue } from "vue-property-decorator";
import { ResponsePagination } from "@/models/interface/common.interface";
import { currencyFormat } from "@/validator/globalvalidator";
import moment from "moment";
import { debounceProcess } from "@/helpers/debounce";
import { logisticServices } from "@/services/logistic.service";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import printJs from "print-js";
import { DataWarehouseBranch } from "@/models/interface/logistic.interface";
import { contactServices } from "@/services/contact.service";
import {
  ContactData,
  ResponseListMaster,
} from "@/models/interface/contact.interface";
import { invoiceServices } from "@service/invoice.service";
import {
  ApproveInvoice,
  DataResponseListArInvoice,
  GenerateReceipt,
  ParamDownloadListInvoice,
} from "@interface/invoice.interface";
import { masterServices } from "@/services/master.service";
import {
  DATE_FORMAT_REPORT,
  DEFAULT_DATE_FORMAT,
} from "@constant/date.constant";
import {
  APP_COMPANY_NAME,
  DEFAULT_PAGE_SIZE,
} from "@/models/constant/global.constant";
import { downloadFile } from "@/helpers/file-reader";
import MNotificationVue from "@/mixins/MNotification.vue";
import { WrappedFormUtils } from "ant-design-vue/types/form/form";
import { trimSpaceToUnderscore } from "@/helpers/common";
import { accountingCurrencyService } from "@/services-v2/accounting-currency.service";
import { IOption } from "@/models/interface-v2/common.interface";
import { ResponseAccountingCurrency } from "@/models/interface-v2/accounting-account.interface";

@Component({
  name: "Invoices",
  mixins: [MNotificationVue],
})
export default class Invoices extends Vue {
  DEFAULT_DATE_FORMAT = DEFAULT_DATE_FORMAT;
  form!: WrappedFormUtils;
  formPosting!: WrappedFormUtils;
  modalPosting = false as boolean;
  formPrint!: WrappedFormUtils;
  modalPrint = false as boolean;
  totalData = 0 as number;
  page = 1 as number;
  limit = DEFAULT_PAGE_SIZE as number;
  asOfDate = "" as string;
  idProduct = "" as string;
  idWarehouse = "" as string;
  idBranch = "" as string;
  productCode = "" as string;
  productName = "" as string;
  dataSourcePrint = [] as any[];
  selectedRowKeys = [] as number[];
  selectedRowKeysModal = [] as number[];
  dataBranch = [] as DataWarehouseBranch[];
  dataCustomer = [] as ContactData[];
  dataInvoiceNumber = [] as DataResponseListArInvoice[];
  dataInvoiceStatus = [] as ResponseListMaster[];
  dataCurrency = [] as IOption<ResponseAccountingCurrency>[];
  loadingBranch = false as boolean;
  loadingCustomer = false as boolean;
  loadingInvoiceNumber = false as boolean;
  loadingInvoiceStatus = false as boolean;
  loadingFind = false as boolean;
  loadingDownload = false as boolean;
  loadingPrint = false as boolean;
  loadingPosting = false as boolean;
  loadingCurrency = false;
  dataSource = [] as DataResponseListArInvoice[];
  listIdInvoice = [] as any[];
  checkDatePosting = "" as string;
  validate = {
    print: false as boolean,
    posting: false as boolean,
  };
  columnsTable = [
    {
      title: this.$t("lbl_invoice_number"),
      dataIndex: "documentNumber",
      key: "documentNumber",
      width: 150,
      scopedSlots: { customRender: "documentNumber" },
    },
    {
      title: this.$t("lbl_customer_name"),
      dataIndex: "customerName",
      key: "customerName",
      width: 150,
      scopedSlots: { customRender: "customerName" },
    },
    {
      title: this.$t("lbl_invoice_date"),
      dataIndex: "invoiceDate",
      key: "invoiceDate",
      width: 150,
      scopedSlots: { customRender: "invoiceDate" },
    },
    {
      title: this.$t("lbl_invoice_amount"),
      dataIndex: "invoiceAmount",
      key: "invoiceAmount",
      width: 150,
      scopedSlots: { customRender: "isCurrency" },
    },
    {
      title: this.$t("lbl_journal_number"),
      dataIndex: "journalNo",
      key: "journalNumber",
      width: 150,
      scopedSlots: { customRender: "isJournal" },
    },
    {
      title: this.$t("lbl_status"),
      dataIndex: "status",
      key: "status",
      width: 150,
      scopedSlots: { customRender: "status" },
    },
    {
      title: this.$t("lbl_action"),
      dataIndex: "operation",
      key: "operation",
      scopedSlots: { customRender: "operation" },
      button: ["view"],
      width: 120,
      align: "center",
    },
  ];
  formRules = {
    branch: {
      label: "lbl_branch",
      name: "branch",
      placeholder: "lbl_choose",
      decorator: [
        "branch",
        {
          rules: [
            {
              required: true,
              message: (): string =>
                this.$t("lbl_validation_required_error").toString(),
            },
          ],
        },
      ],
    },
    currencyId: {
      label: "lbl_currency",
      name: "currency",
      placeholder: "lbl_choose",
      decorator: [
        "currencyId",
        {
          rules: [
            {
              required: true,
              message: (): string =>
                this.$t("lbl_validation_required_error").toString(),
            },
          ],
        },
      ],
    },
    customerName: {
      label: "lbl_customer_name",
      name: "customerName",
      placeholder: "lbl_choose",
      decorator: [
        "customerName",
        {
          rules: [
            {
              required: false,
              message: (): string =>
                this.$t("lbl_validation_required_error").toString(),
            },
          ],
        },
      ],
    },
    invoiceDateFrom: {
      label: "lbl_invoice_date_from",
      name: "invoiceDateFrom",
      placeholder: "lbl_choose",
      decorator: [
        "invoiceDateFrom",
        {
          rules: [
            {
              required: false,
              message: (): string =>
                this.$t("lbl_validation_required_error").toString(),
            },
          ],
        },
      ],
    },
    invoiceDateTo: {
      label: "lbl_invoice_date_to",
      name: "invoiceDateTo",
      placeholder: "lbl_choose",
      decorator: [
        "invoiceDateTo",
        {
          rules: [
            {
              required: false,
              message: (): string =>
                this.$t("lbl_validation_required_error").toString(),
            },
          ],
        },
      ],
    },
    invoiceNumber: {
      label: "lbl_invoice_number",
      name: "invoiceNumber",
      placeholder: "lbl_choose",
      decorator: [
        "invoiceNumber",
        {
          rules: [
            {
              required: false,
              message: (): string =>
                this.$t("lbl_validation_required_error").toString(),
            },
          ],
        },
      ],
    },
    invoiceStatus: {
      label: "lbl_invoice_status",
      name: "invoiceStatus",
      placeholder: "lbl_choose",
      decorator: [
        "invoiceStatus",
        {
          rules: [
            {
              required: false,
              message: (): string =>
                this.$t("lbl_validation_required_error").toString(),
            },
          ],
        },
      ],
    },
  };
  formRulesModal = {
    invoiceReceivedDate: {
      label: "lbl_invoice_receive_date",
      name: "invoiceReceivedDate",
      placeholder: "lbl_choose",
      decorator: [
        "invoiceReceivedDate",
        {
          rules: [
            {
              required: false,
              message: (): string =>
                this.$t("lbl_validation_required_error").toString(),
            },
          ],
        },
      ],
    },
    picCustomerName: {
      label: "lbl_pic_customer_name",
      name: "picCustomerName",
      placeholder: "lbl_choose",
      decorator: [
        "picCustomerName",
        {
          rules: [
            {
              required: false,
              message: (): string =>
                this.$t("lbl_validation_required_error").toString(),
            },
          ],
        },
      ],
    },
  };
  formRulesModalPrint = {
    customerName: {
      label: "lbl_customer_name",
      name: "customerName",
      placeholder: "lbl_chosoe",
      decorator: [
        "customerName",
        {
          rules: [
            {
              required: false,
              message: (): string =>
                this.$t("lbl_validation_required_error").toString(),
            },
          ],
        },
      ],
    },
    billToAddress: {
      label: "lbl_bill_address",
      name: "billToAddress",
      placeholder: "lbl_choose",
      decorator: [
        "billToAddress",
        {
          rules: [
            {
              required: false,
              message: (): string =>
                this.$t("lbl_validation_required_error").toString(),
            },
          ],
        },
      ],
    },
    branch: {
      label: "lbl_branch",
      name: "branch",
      placeholder: "lbl_choose",
      decorator: [
        "branch",
        {
          rules: [
            {
              required: false,
              message: (): string =>
                this.$t("lbl_validation_required_error").toString(),
            },
          ],
        },
      ],
    },
  };
  columnsTablePrint = [
    {
      title: this.$t("lbl_number_short"),
      dataIndex: "no",
      key: "no",
      width: 70,
      scopedSlots: { customRender: "isNull" },
      responsiveColInput: [
        {
          name: "documentDescription",
          placeholder: this.$t("lbl_type_here"),
          style: "width: 100%;",
        },
        {
          name: "totalQty",
          placeholder: this.$t("lbl_type_here"),
          style: "width: 100%;",
        },
        {
          name: "unit",
          placeholder: this.$t("lbl_type_here"),
          style: "width: 100%;",
        },
        {
          name: "notes",
          placeholder: this.$t("lbl_notes"),
          style: "width: 100%;",
        },
      ],
    },
    {
      title: this.$t("lbl_document"),
      dataIndex: "documentDescription",
      key: "documents",
      scopedSlots: { customRender: "documentDescription" },
    },
    {
      title: this.$t("lbl_qty"),
      dataIndex: "totalQty",
      key: "qty",
      scopedSlots: { customRender: "totalQty" },
    },
    {
      title: this.$t("lbl_uom"),
      dataIndex: "unit",
      key: "unit",
      scopedSlots: { customRender: "unit" },
    },
    {
      title: this.$t("lbl_notes"),
      dataIndex: "notes",
      key: "notes",
      scopedSlots: { customRender: "notes" },
    },
  ];
  init = true;
  created(): void {
    this.form = this.$form.createForm(this, { name: "invoice" });
    this.formPosting = this.$form.createForm(this, { name: "formPosting" });
    this.formPrint = this.$form.createForm(this, { name: "formPrint" });

    this.getCustomerName = debounceProcess(this.getCustomerName, 300);
    this.getInvoiceNumber = debounceProcess(this.getInvoiceNumber, 300);
    this.getInvoiceStatus = debounceProcess(this.getInvoiceStatus, 300);
    this.getBranch = debounceProcess(this.getBranch, 300);

    this.getBranch("");
    this.getCustomerName("");
    this.getInvoiceNumber("");
    this.getInvoiceStatus("");
    this.searchCurrency();
  }

  get hasPrivilegeCreate(): boolean {
    return this.$store.getters["authStore/GET_USER_PRIVILEGES"].find(
      (x) => x.key === "invoice-ar" && x.privilege.create
    );
  }

  async searchCurrency(search = ""): Promise<void> {
    try {
      this.loadingCurrency = true;
      const params: RequestQueryParamsModel = {
        limit: DEFAULT_PAGE_SIZE,
        page: 0,
      };
      if (search)
        params.search = `currencyCode~*${search}*_OR_description~*${search}`;
      const res = await accountingCurrencyService.listOfMasterCurrency(params);
      this.dataCurrency = res.data.map((x) => ({
        key: x.currencyCode,
        value: x.id,
        meta: x,
      }));
      if (this.init) {
        const currIdr = this.dataCurrency.find((x) => x.key === "IDR");
        if (!currIdr) return;
        this.form.setFieldsValue({ currencyId: currIdr.value });
      }
    } catch (error) {
      this.showErrorMessage("notif_process_fail");
    } finally {
      this.loadingCurrency = false;
    }
  }

  cancelPrint(): void {
    this.modalPrint = false;
  }
  handleClear(): void {
    this.form.resetFields();
  }
  print(): void {
    this.formPrint.validateFields((err, value) => {
      if (!err) {
        const params = {
          params:
            this.$store.state.authStore.authData.companyName ||
            APP_COMPANY_NAME,
        } as RequestQueryParamsModel;
        const dataObj = this.dataSourcePrint.map((item) => {
          return {
            documentDescription: item.documentDescription,
            id: item.id ? item.id : null,
            notes: item.notes,
            totalQty: item.totalQty,
            unit: item.unit,
          };
        });
        const payload = {
          branchName: value.branch,
          customerName: value.customerName,
          billToAddress: value.billToAddress,
          details: dataObj as [],
        } as GenerateReceipt;
        invoiceServices.printReceipt(payload, params).then((dataBlob) => {
          if (dataBlob) {
            const url = window.URL.createObjectURL(new Blob([dataBlob]));
            printJs({
              printable: url,
              type: "pdf",
              targetStyles: "*",
              showModal: true,
              modalMessage: "Loading ...",
            });
            this.modalPrint = false;
          }
        });
      }
    });
  }
  handlePosting(): void {
    const dateList = [] as any;
    if (this.listIdInvoice.length > 0) {
      const valueCustomer = this.listIdInvoice.map((item) => {
        return item.customerName;
      });
      const status = this.listIdInvoice.map((item) => {
        return item.status;
      });
      this.listIdInvoice.forEach((item, idx, array) => {
        dateList.push({
          calculate: moment().diff(item.date, "days"),
          date: item.date,
        });
        if (idx === array.length - 1) {
          dateList.sort((a, b) => a.calculate - b.calculate);
          this.checkDatePosting = dateList[0].date;
        }
      });
      this.formPosting.resetFields();
      const allCustomerEqual = (arr) => arr.every((val) => val === arr[0]);
      const allStatusEqual = (arr) =>
        arr.every((v) => v === "Delivered" || v === "Need Approval");
      const sameDataCustomer = allCustomerEqual(valueCustomer);
      const sameDataStatus = allStatusEqual(status);
      if (sameDataCustomer && sameDataStatus) {
        this.modalPosting = true;
      } else
        this.showNotifError(this.$t("lbl_invalid_posting_customer_status"));
    } else {
      this.showNotifError(this.$t("lbl_select_one_row_to_print"));
    }
  }
  handleClickColumn(record): void {
    this.$router.push("/generaljournal/journal/detail/" + record.journalId);
  }
  responseViewTable(response): void {
    this.$router.push({
      name: "accountreceivables.invoices.edit",
      params: { id: response.data.id },
      query: { status: response.data.status },
    });
  }
  onSelectChange(value: number[]): void {
    this.selectedRowKeys = value;
    this.listIdInvoice = [];
    value.forEach((element) => {
      this.listIdInvoice.push({
        id: this.dataSource[element].id,
        customerName: this.dataSource[element].customerName,
        status: this.dataSource[element].status,
        date: this.dataSource[element].invoiceDate,
      });
    });
  }
  handleCreate(): void {
    this.$router.push("/account-receivables/invoices/create");
  }
  getBranch(valueSearch: string): void {
    const params: RequestQueryParamsModel = {
      page: 0,
      limit: DEFAULT_PAGE_SIZE,
      sort: "createdDate:desc",
    };
    if (valueSearch)
      params.search = `name~*${valueSearch}*_OR_code~*${valueSearch}*_OR_address~*${valueSearch}`;
    this.loadingBranch = true;
    logisticServices
      .listWarehouseBranch(params, "")
      .then((response) => {
        this.dataBranch = response.data;
        this.form.setFieldsValue({
          branch: response.data[0].id,
        });
        if (this.init) {
          this.init = false;
          this.handleFind(false);
        }
      })
      .finally(() => (this.loadingBranch = false));
  }
  addRow(): void {
    this.dataSourcePrint = [
      ...this.dataSourcePrint,
      {
        key: this.dataSourcePrint.length,
        documentDescription: "",
        notes: "",
        totalQty: "",
        no: this.dataSourcePrint.length + 1 + ".",
        unit: "",
      },
    ];
  }
  handleInput(value, key, objectColInput): void {
    this.dataSourcePrint[key][objectColInput.name] = value;
    this.dataSourcePrint = this.dataSourcePrint.slice();
  }
  showConfirmation() {
    if (this.selectedRowKeysModal.length > 0) {
      this.$confirm({
        title: this.$t("lbl_modal_delete_title_confirm"),
        content: this.$t("lbl_modal_delete_info", {
          count: this.selectedRowKeysModal.length,
        }),
        onOk: () => {
          this.dataSourcePrint = this.dataSourcePrint.filter((data) => {
            return !this.selectedRowKeysModal.includes(data.key);
          });
          this.dataSourcePrint.forEach((data, index) => (data.key = index));

          this.dataListItems = this.dataSourcePrint.slice();
          this.selectedRowKeysModal = [];
          this.dataSourcePrint.forEach((item, index) => {
            item.no = index + 1 + ".";
          });
        },
        onCancel() {
          return;
        },
      });
    } else {
      this.showNotifError("lbl_modal_delete_error_description");
    }
  }
  onSelectChangeModal(selectedRowKeys: number[]): void {
    this.selectedRowKeysModal = selectedRowKeys;
  }
  approve(): void {
    this.formPosting.validateFields((err, value) => {
      if (!err) {
        const id = this.listIdInvoice.map((item) => {
          return item.id;
        });
        const payload = [
          {
            invoiceReceivedDate: moment(value.invoiceReceivedDate).format(),
            picCustomerName: value.picCustomerName,
          },
        ] as ApproveInvoice[];
        invoiceServices.approveInvoice(payload, id.toString()).then(() => {
          this.modalPosting = false;
          this.selectedRowKeys = [];
          this.handleFind(false);
        });
      }
    });
  }
  cancel(): void {
    this.modalPosting = false;
  }
  getCustomerName(valueSearch: string): void {
    const params: RequestQueryParamsModel = {
      limit: DEFAULT_PAGE_SIZE,
      page: 0,
      search: "customer~true_AND_active~true",
      sorts: "firstName:asc",
    };
    if (valueSearch) {
      params.search += `_AND_firstName~*${valueSearch}*_OR_lastName~*${valueSearch}*`;
    }
    this.loadingCustomer = true;
    contactServices
      .listContactData(params)
      .then((response) => (this.dataCustomer = response.data))
      .finally(() => (this.loadingCustomer = false));
  }
  getInvoiceNumber(valueSearch: string): void {
    const params: RequestQueryParamsModel = {
      limit: DEFAULT_PAGE_SIZE,
    };
    if (valueSearch) params.search = `documentNumber~*${valueSearch}*`;
    this.loadingInvoiceNumber = true;
    invoiceServices
      .getListInvoiceAR(params)
      .then((response) => {
        this.dataInvoiceNumber = response.data;
      })
      .finally(() => (this.loadingInvoiceNumber = false));
  }
  getInvoiceStatus(valueSearch: string): void {
    const params: RequestQueryParamsModel = {
      page: 0,
      limit: DEFAULT_PAGE_SIZE,
      name: "INVOICE_AR_STATUS",
    };
    if (valueSearch) params.search = `value~*${valueSearch}*`;
    this.loadingInvoiceStatus = true;
    masterServices
      .listMaster(params)
      .then((response) => {
        this.dataInvoiceStatus = response;
      })
      .finally(() => (this.loadingInvoiceStatus = false));
  }
  handleDownload(): void {
    this.form.validateFields((err, res) => {
      if (err) return;
      this.loadingDownload = true;
      const params: ParamDownloadListInvoice = {
        branchId: res.branch,
        currencyId: res.currencyId,
      };
      if (res.customerName) params.customerId = res.customerName;
      if (res.invoiceDateFrom)
        params.startDate = moment(res.invoiceDateFrom).format(
          DATE_FORMAT_REPORT
        );
      if (res.invoiceDateTo)
        params.endDate = moment(res.invoiceDateTo).format(DATE_FORMAT_REPORT);
      invoiceServices
        .downloadList(params)
        .then((data) => {
          if (data) {
            let fileName = "report_invoices.xlsx";
            if (params.startDate && params.endDate)
              fileName = `report_invoices_from_${params.startDate ||
                "-"}_to_${params.endDate || "-"}.xlsx`;
            downloadFile(new Blob([data]), fileName);
          }
        })
        .finally(() => (this.loadingDownload = false));
    });
  }
  changeToCurrencyFormat(val): string {
    if (val === 0) {
      return "0";
    } else {
      return currencyFormat(val);
    }
  }
  assignSearch(key, value, and): string {
    if (key === "branch" && value)
      return and + `branchWarehouse.secureId~*${value}*`;
    else if (key === "invoiceType" && value)
      return and + `invoiceType~*${value}*`;
    else if (key === "source" && value) return and + `invoiceSource~*${value}*`;
    else if (key === "customerName" && value)
      return and + `customer.secureId~*${value}*`;
    else if (key === "invoiceDateFrom" && value)
      return (
        and +
        `invoiceDate>=${moment(value)
          .set({ hour: 0, minute: 0, second: 0 })
          .utcOffset("+07")
          .format()}`
      );
    else if (key === "invoiceDateTo" && value)
      return (
        and +
        `invoiceDate<=${moment(value)
          .set({ hour: 23, minute: 59, second: 59 })
          .utcOffset("+07")
          .format()}`
      );
    else if (key === "invoiceNumber" && value)
      return and + `documentNumber~*${value}*`;
    else if (key === "invoiceStatus" && value)
      return and + `status~${trimSpaceToUnderscore(value)}`;
    else if (key === "currencyId" && value)
      return and + `priceCurrency.secureId~${value}`;
    else return "";
  }
  dynamicSearch(res): string {
    let search = "";
    Object.keys(res).forEach((key) => {
      if (!search) {
        search = this.assignSearch(key, res[key], "");
      } else {
        search += this.assignSearch(key, res[key], "_AND_");
      }
    });
    return search;
  }
  disabledDate(current): boolean {
    return (
      current < moment(this.checkDatePosting, DEFAULT_DATE_FORMAT) ||
      current > moment()
    );
  }
  handleFind(page: boolean): void {
    this.form.validateFields((err, res) => {
      if (err) return;
      const params: RequestQueryParamsModel = {
        limit: this.limit,
        page: page ? this.page - 1 : 0,
        sorts: "createdDate:desc",
      };
      params.search = this.dynamicSearch(res);
      this.loadingFind = true;
      invoiceServices
        .getListInvoiceAR(params)
        .then((response) => {
          this.totalData = response.totalElements;
          this.dataSource = response.data.map((dataMap, index) => {
            return {
              ...dataMap,
              key: index,
              invoiceDate: moment(dataMap.invoiceDate).format(
                DEFAULT_DATE_FORMAT
              ),
            };
          });
        })
        .finally(() => (this.loadingFind = false));
    });
  }
  responsePageSizeChange(response: ResponsePagination): void {
    this.limit = response.size;
    this.page = 1;
    this.handleFind(true);
  }
  responseCurrentPageChange(response: ResponsePagination): void {
    this.page = response.page;
    this.handleFind(true);
  }
  filterOption(input, option) {
    return (
      option.componentOptions.children[0].componentOptions.children[1].text
        .toLowerCase()
        .indexOf(input.toLowerCase()) >= 0
    );
  }
}
