import { Api } from "@/models/class/api.class";
import { RequestAccountingCurrencyCreate, ResponseAccountingCurrencyCreate, ResponseListAccountingCurrency } from "@/models/interface-v2/currency.interface";
import { ErrorResponseModel, RequestQueryParamsModel } from "@/models/interface/http.interface";
import { HttpClient } from "@/services/http.service";
import { AxiosError } from "axios";

export class AccountingCurrencyServices extends HttpClient {
  constructor() {
    super();
  }

  public createMasterCurrency(payload: RequestAccountingCurrencyCreate): Promise<ResponseAccountingCurrencyCreate> {
    return this.post<ResponseAccountingCurrencyCreate, RequestAccountingCurrencyCreate>(Api.Currency, payload)
    .then(this.success)
    .catch((error: AxiosError<ErrorResponseModel>) => {
      throw this.error(error);
    });
  }

  public listOfMasterCurrency(params: RequestQueryParamsModel): Promise<ResponseListAccountingCurrency> {
    return this.get<ResponseListAccountingCurrency>(Api.Currency, { params })
    .then(this.success)
    .catch((error: AxiosError<ErrorResponseModel>) => {
      throw this.error(error);
    });
  }

}

export const accountingCurrencyService = new AccountingCurrencyServices();
