// Core
import { AxiosError} from "axios";
import { HttpClient } from "./http.service";
// Class
import { Api } from "@/models/class/api.class";

// Interfaces
import { ErrorResponseModel , RequestQueryParamsModel } from "@/models/interface/http.interface";
import {
  ResponseRegion,
  ResponseListContactData,
  CreateContactData,
  ResponseContactData
} from '@/models/interface/contact.interface'

export class ContactServices extends HttpClient {
  public constructor() {
    super();

    this.listRegion = this.listRegion.bind(this)
    this.listContactData = this.listContactData.bind(this)
    this.createContactData = this.createContactData.bind(this)
    this.getContactData = this.getContactData.bind(this)
  }
  
  public listRegion(params: RequestQueryParamsModel): Promise<ResponseRegion> {
    return this.get<ResponseRegion>(Api.Region, { params })
    .then(this.success)
    .catch((error: AxiosError<ErrorResponseModel>) => {
      throw this.error(error)
    })
  }

  downloadReportContact = (params: RequestQueryParamsModel) :Promise<Blob> => {
    return this.get<Blob>(Api.ContactData + `/report` , { params,  responseType: 'arraybuffer' })
    .then(this.success)
    .catch((error: AxiosError<ErrorResponseModel>) => {
      throw this.error(error)
    })
  }

  public listContactData (params: RequestQueryParamsModel): Promise<ResponseListContactData> {
    return this.get<ResponseListContactData>(Api.ContactData, { params })
    .then(this.success)
    .catch((error: AxiosError<ErrorResponseModel>) => {
      throw this.error(error)
    })
  }

  public createContactData (payload: CreateContactData): Promise<boolean> {
    return this.post<boolean, CreateContactData>(Api.ContactData, payload)
    .then(this.success)
    .catch((error: AxiosError<ErrorResponseModel>) => {
      throw this.error(error)
    })
  }

  public updateContactData (payload: CreateContactData, id: string): Promise<boolean> {
    return this.put<boolean, CreateContactData>(Api.ContactData + `/${id}`, payload)
    .then(this.success)
    .catch((error: AxiosError<ErrorResponseModel>) => {
      throw this.error(error)
    })
  }

  public getContactData (id:string): Promise<ResponseContactData> {
    return this.get<ResponseContactData>(Api.ContactData + `/${id}`)
    .then(this.success)
    .catch((error: AxiosError<ErrorResponseModel>) => {
      throw this.error(error)
    })
  }

}
export const contactServices = new ContactServices();
